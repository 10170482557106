.notFoundSection {
    text-align: center;
    padding: 80px 0;
}

.notFoundSection .container {
    max-width: 600px;
}

.notFoundSection .pageTitle span {
    display: block;
    font-family: 'Futura LT', Arial, Helvetica, sans-serif;
    font-size: 49px;
    margin: 20px 0 0;
}

.notFoundSection p {
    margin: 0 0 30px;
}

@media(max-width: 767px) {
    .notFoundSection {
        padding: 50px 0;
    }
    .notFoundSection .pageTitle img {
        max-width: 260px;
    }

    .notFoundSection .pageTitle span {
        font-size: 26px;
    }
}