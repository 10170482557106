@media only screen and (min-width: 768px) {
	.header-menu .collapse:not(.show) { display: flex !important; }
	
	
}

/*==========================================================================================================*/
@media only screen and (max-width: 1600px) {
	h1, .h1 { font-size: 14rem; }
	.page-title h1 { margin-top: 1.2rem; }
	.page-title-box { padding: 2.5rem 0; }
	h2, .h2 { font-size: 4.925rem; }
	.highlight-text h2 { font-size: 13.75rem; }
	.page-title figure { margin-bottom: 0; }
	.accordion .accordion-button { font-size: 3rem; }
	.accordion .accordion-item { padding-bottom: 3.5rem; margin-bottom: 3.5rem; }
	.domain-process-step span, .domain-process-step h2 { margin-bottom: 1.2rem; }
	.whos-video { width: 7.75rem; min-width: 7.75rem; margin: 0 1.5rem; }
	.page-title.accredited-registrars-title h1 { font-size: 19vmin; }
	.footer-bottom-note p { max-width: 820px; width: 100%; padding-right: 1rem; }
}
	
	
/*==========================================================================================================*/
@media only screen and (max-width: 1399px) {
	.btn { padding: 0.813rem 1.513rem; font-size: 1rem; }
	.video-btn { height: 6rem; width: 6rem; }
	.video-btn::before { border-top-width: 0.9rem; border-bottom-width: 0.9rem; border-left-width: 1.2rem; }
	.highlight-text h2 { font-size: 10.75rem; }
	.stories-slider .splide__arrows { /*top: 39.5rem;*/ top: 42rem; }
	.splide__arrows .splide__arrow { height: 3.3rem; width: 3.3rem; }
	.splide__arrows .splide__arrow svg { height: 1rem; width: 1rem; }
	h1, .h1 { font-size: 12rem; }
	h2, .h2 { font-size: 3.925rem; }
	.section-row { padding: 2.925rem 0; }
	.footer-search .form-control { font-size: 2.8rem; }
	.stories-slider { max-width: 600px; }
	.footer-search .btn { min-width: 152px; }
	.footer-search .btn svg { min-height: 1.638rem; height: 1.638rem; min-width: 1.638rem; width: 1.638rem; }
	.post-details-box, .accordion .accordion-body { font-size: 1.4rem; }
	.post-video-box { margin: 6rem 0; }
	h3 { font-size: 2.6rem; }
	.highlight-text h2 .highlight-video { min-width: 11rem; }
	.accordion .accordion-button { font-size: 2.8rem; }
	.accordion .accordion-item { padding-bottom: 3.2rem; margin-bottom: 3.2rem; }
	.tld-tabs-title-box .btn { min-width: 108px; }
	.sliderwith-no-title .splide__arrows.splide__arrows--ltr { top: 24.5rem; }
	.whos-video { width: 5.75rem; min-width: 5.75rem; margin: 0 0.9rem; }
	.page-title.accredited-registrars-title h1 { font-size: 16vmin; }
	.main-banner-box h1 { font-size: 18vmin; margin: 0.9rem 0 0 0; }
	.heading-video { margin: -0.7rem auto 0.4rem; max-width: 32vmin; }
	.down-arrow-anim { margin: 0.3rem auto 0.6rem; }
	.home-page-search .page-search .form-control { /*font-size: 2.538rem;*/ font-size: 2.338rem; }
	.main-banner-box { height: calc(100vh - 148px); }
	body { font-size: 17px; line-height: 1.35; }
	.down-arrow-anim { height: 2.413rem; width: 2.413rem; }
	.down-arrow-anim svg { width: 13px; height: 18px; }
}

@media only screen and (max-width: 1300px) {
	.post-main-img img { height: auto; }
	.main-banner-box h1 { margin: 0 0 0 0; }

}
/*==========================================================================================================*/
@media only screen and (max-width: 1199px) {
	.container { width: 90%; }
	.page-title-box { padding: 2rem 0; }
	h1, .h1 { font-size: 10rem; }
	body { font-size: 17px; }
	.hignlight-story-box h2 { margin-bottom: 0.2rem; }
	.footer-search {  padding: 1.5rem 2rem; }
	.section-row { padding: 2rem 0; }
	.page-search.footer-search .form-control { font-size: 2rem; }
	.post-details-box, .accordion .accordion-body { font-size: 1.3rem; }
	.post-video-box { margin: 5rem 0; }
	h3 { font-size: 2.2rem; }
	.post-details-box p + h3, .post-details-box p + h4, .post-details-box p + h5, .post-details-box p + h6 { margin-top: 6rem; }
	.accordion .accordion-button { font-size: 2.5rem; padding-right: 3rem; }
	.accordion .accordion-item { padding-bottom: 3rem; margin-bottom: 3rem; }
	.accordion-button::before, .accordion-button::after { width: 2.25rem; }
	.video-piller span:nth-child(1)::after, .video-piller span:nth-child(3)::after { height: 5rem; }
	.screen-video { padding: 17rem 0; }
	.screen-video-box { margin-bottom: 5rem; }
	.page-title.accredited-registrars-title h1 { font-size: 13vmin; }
}

/*==========================================================================================================*/
@media only screen and (max-width: 991px) {
	.hignlight-story-box { max-width: 100%; }
	body { font-size: 16px; }
	.post-details-box, .accordion .accordion-body { font-size: 1.2rem; }
	.post-video-box { margin: 4rem 0; }
	h3 { font-size: 2.1rem; }
	.post-details-box p + h3, .post-details-box p + h4, .post-details-box p + h5, .post-details-box p + h6 { margin-top: 5rem; }
	.accordion .accordion-button { font-size: 3rem; }
	.accordion .accordion-button { font-size: 2rem; }
	.accordion .accordion-item { padding-bottom: 2.5rem; margin-bottom: 2.5rem; }
	.heading-video { width: 40%; margin: -0.4rem auto 0.4rem; }
	.video-piller span:nth-child(1)::after, .video-piller span:nth-child(3)::after { height: 4rem; }
	.screen-video { padding: 14rem 0; }
	.screen-video-box { margin-bottom: 4rem; }
	.main-banner-box { height: auto; }
	.main-banner-box .container > span > span { transform: none !important; margin-bottom: 1rem; }
	.stories-box { padding-right: 2.5rem; }
	.footer-search .form-control { font-size: 2rem; }

} 

/*==========================================================================================================*/
@media only screen and (max-width: 767px) {
	.container { width: 100%; padding-left: 1rem; padding-right: 1rem; }
	.logo { max-width: 50px; }
	body { font-size: 14px; }
	#header { padding: 1.2rem 0.5rem; z-index: 14; }
	#header .navbar-toggler { display: block; }
	.header-menu .nav-item a { padding: 0.3rem 0; }
	
	#footer { padding: 1.5rem 1rem; }
	.footer-bottom, #footer { font-size: 16px; }
	.flogo { max-width: 70px; }
	.gd-powred-by-logo { max-width: 165px; max-width: 200px; }
	.footer-logo-box { justify-content: center; margin-bottom: 1.6rem; }
	.fsocial-box { justify-content: center; }
	.fmenu ul { flex-direction: column; align-items: center; }
	.fmenu ul li { margin: 0.4rem 0; }
	.footer-bottom-menu ul { align-items: center; flex-direction: column; }
	.footer-bottom-sell-note { text-align: center; color: #AFAFAF; margin-bottom: 1rem; }
	.footer-bottom-right { text-align: center; }
	.footer-bottom-menu ul li { margin: 0.4rem 0; }

	.header-menu .navbar-collapse { position: absolute; left: 0; right: 0; top: 100%; background: #000; /*max-height: calc(100vh - 62px);*/ /*transform: translateX(100%); opacity: 0; visibility: hidden; transition: 0.5s all;*/ }
	nav.menu { height: calc(100vh - 62px); overflow: auto; }
	.header-menu .navbar-nav { height: 100%; overflow: auto; }
	/*.header-menu .navbar-collapse.show { transform: translateX(0); opacity: 1; visibility: visible; }*/
	.header-menu .navbar-nav { flex-direction: column; font-size: 5.625rem; padding: 0 2.5rem 2.5rem 2.5rem; border-left: 15px solid #5CFFFA; clip-path: inset(0% round 0) !important; }
	.header-menu .navbar-nav:before { content: ""; position: absolute; left: 0; right: 0; top: 0; height: 33.333333%; width: 15px; background: #FF00B8; }
	.header-menu .navbar-nav:after { content: ""; position: absolute; left: 0; right: 0; top: 33.333333%; height: 33.333333%; width: 15px; background: #03F500; }
	.header-menu .nav-item { margin-left: 0; line-height: 1; font-family: 'Tusker Grotesk 3700'; font-weight: bold; }
	.header-menu { margin-left: 0; }
	.menu-left-line { display: block; }
	body { font-size: 15px; }
	.video-btn { height: 4rem; width: 4rem; }
	.video-btn::before { border-top-width: 0.7rem; border-bottom-width: 0.7rem; border-left-width: 1rem; }
	.highlight-text h2 { font-size: 5.35rem; }
	.top-line-box { height: 0.525rem; }
	.highlight-text { padding-top: 1.2rem; }
	.stories-slider .splide__arrows { position: static; justify-content: center; margin-top: 2rem; width: 85%; margin-left: auto; margin-right: 0; }
	.stories-slider { display: flex; flex-direction: column-reverse; }
	.showcase-box { margin: 1.2rem 0; }
	body { padding-top: 62px; }
	.page-title figure { max-width: 80px; }
	h2, .h2 { font-size: 3rem; }
	.story-video-box img { min-height: 450px; object-fit: cover; }
	.stories-mian-box { padding: 0 0.7rem; }
	.splide.stories-slider { position: relative; margin-left: 0; margin-right: 0; width: 85%; max-width: inherit; }
	h1, .h1 {  font-size: 8rem; }
	.splide.stories-slider::after { content: ""; position: absolute; right: 100%; top: 0; bottom: 0; background: #000; width: 100px; }
	.stories-btn-box a { margin-right: 1.3rem; }
	.showcase-list .row > div:last-child { margin-bottom: 0 !important; }
	.showcase-list .row > div:last-child .showcase-box { margin-bottom: 0; }
	.showcase-box h2 { margin: 0.5rem 0 0 0; }
	.footer-search .btn svg { min-height: 1.338rem; height: 1.338rem; min-width: 1.338rem; width: 1.338rem; margin: 0; }
	.footer-search .btn { min-width: inherit; font-size: 0; min-width: 50px; padding: 0; display: flex; align-items: center; justify-content: center; height: 50px; border-color: transparent; } 
	.footer-search .form-control, .page-search.footer-search .form-control { font-size: 1.5rem; }
	.page-search.footer-search.search-sticky .form-control, .home-page-search .page-search.search-sticky .form-control { font-size: 1.2rem; }
	.footer-search { padding: 0.7rem 1rem; }
	.page-search.footer-search { padding: 0.4rem 1rem 0.4rem 2rem; top: 61px; }
	.home-page-search { top: 61px; }
	.page-search.footer-search .form-control { padding-right: 0; }
	.post-details-box, .accordion .accordion-body { font-size:1rem; }
	.post-video-box { margin: 3rem 0; }
	.post-details-box p, .post-details-box h2, .post-details-box h3, .post-details-box h4, .post-details-box h5, .post-details-box h6, .post-details-box ul { margin-bottom: 1.375rem; max-width: 100%; width: 100%; }
	.post-details-box h1 { max-width: 100%; width: 100%; }
	.post-video-box .top-line-box { left: 1rem; right: 1rem; }
	.post-info h5 { margin: 0 0 0.2rem 0; }
	h3 { font-size: 1.8rem; }
	.post-details-box p + h3, .post-details-box p + h4, .post-details-box p + h5, .post-details-box p + h6 { margin-top: 4rem; }
	.post-details-box p:last-child { margin-bottom: 0; }
	.highlight-video h6 { font-size: 0.6rem; }
	.highlight-text h2 .highlight-video { min-width: 8rem; margin-bottom: 0.4rem; }
	.accordion .accordion-button { font-size: 1.8rem; padding-right: 2.5rem; }
	.accordion .accordion-item { padding-bottom: 2rem; margin-bottom: 2rem; }
	.accordion-button::before, .accordion-button::after { width: 1.813rem; }
	.accordion .accordion-button span, .accordion .accordion-body { max-width: 100%; }
	.accordion .accordion-body { padding: 1.25rem 0 0 0; }
	.contact-address-middle, .contact-address-bottom { margin-top: 2.5rem; margin-bottom: 1.6rem; }
	.contact-from .form-group { margin-bottom: 1.5rem; }
	.contact-from .form-control { height: 3.75rem; }
	.contact-from .form-group label { top: 0.55rem; font-size: 1rem; }
	.contact-from textarea.form-control { height: 13.625rem; }
	.contact-address-middle { max-width: 100%; }
	.contact-address-middle h5 { margin-bottom: 0.75rem; }
	.contact-from .form-control:focus ~ label, .contact-from .form-control.filled ~ label { top: -1.3rem; padding-left: 0.7rem; font-size: 0.9rem; padding-right: 0.7rem; }
	.card.accredited-registrars-list .rdt_TableRow div { white-space: normal !important; }
	.card.accredited-registrars-list .rdt_TableRow > div { padding-top: 0.8rem; padding-bottom: 0.8rem; }
	.card.accredited-registrars-list .rdt_TableHead { margin-bottom: 0.6rem; }
	.card.accredited-registrars-list .rdt_TableHead > div { padding: 1.1rem 0; }
	.card.accredited-registrars-list header .form-control { height: 2.95rem; font-size: 0.925rem; max-width: 240px; }
	.card.accredited-registrars-list header { margin-bottom: 1rem; }
	.card.accredited-registrars-list .rdt_TableRow { font-size: 0.875rem; }
	.clip-img h2 { font-size: 47vw; }
	.gd-ico { margin: 0 auto 2rem; max-width: 5rem; }
	.gd-text h2 { font-size: 5.6rem; line-height: 1.1; }
	.small-container { width: 100%; padding: 0 1rem; }
	.domain-process-step { margin: 1rem 0; max-width: 100%; }
	.domain-process-step span, .domain-process-step h2 { margin-bottom: 0.6rem; }
	.tld-tabs-title-box .btn { min-width: 130px; }
	.tld-name-tabs-box h4 { font-size: 1.3rem; }
	.domain-model .modal-body { padding: 3rem; }
	.trusted-options-triger { padding: 1.2rem 1.675rem 0.9rem; font-size: 0.9rem; }
	.trusted-all-partner-list { padding-top: 1rem; }
	.highlight-text h2 span { margin-right: 0.6rem; }
	.main-banner-box h1 { font-size: 22vmin; margin: 0.9rem 0 0 0; }
	.heading-video { width: 44%; }
	.domain-process-main-box .domain-process-step { text-align: center; }
	.bg-color-piller { top: auto; right: 0; height: 3px; width: 100%; min-width: 100%; }
	.why-tv-box h2, .why-tv-box p { margin: 0.4rem 0; }
	.why-tv-list { padding-left: 1rem; padding-right: 1rem; }
	.why-tv-box { padding-top: 0; padding-bottom: 1.6rem; margin-bottom: 1.6rem; }
	.whos-video { width: 4.75rem; min-width: 4.75rem; margin: 0 0.5rem; }
	.video-piller span:nth-child(1)::after, .video-piller span:nth-child(3)::after { height: 3rem; }
	.screen-video { padding: 11rem 0; }
	.whos-text-details { margin-top: 1rem; }
	.screen-video-box { margin-bottom: 3rem; }
	.post-main-img img { height: auto; }
	.footer-bottom-note p { max-width: 100%; padding-right: 0; }
	.stories-box { padding-right: 0; }
	.main-banner-box { position: relative; z-index: 2; }
	.devider { height: 40px; }
	/*.main-banner-box>.container .text-center { transform: translateY(0); }*/
}
/*==========================================================================================================*/
/* only iphone4 landscape & Potriat 300 by 480*/
@media only screen and (max-width: 575px) {
	.page-title h1 { font-size: 5.625rem; }
	body { font-size: 12px; }
	.page-title figure { max-width: 53px; }
	h2, .h2 { font-size: 2.125rem; }
	.btn { padding: 0.913rem 1.913rem; font-size: 0.75rem; }
	.highlight-text h2 { font-size: 3.875rem; }
	.top-line-box { height: 0.425rem; }
	.highlight-text { padding-top: 0.8rem; }
	.section-title { padding-bottom: 0.6rem; }
	.stories-mian-box { padding: 0 0.469rem; }
	.splide.stories-slider, .stories-slider .splide__arrows { width: 95%; }
	.stories-btn-box a { margin-top: 0.5rem; margin-bottom: 0.5rem; }
	.post-details-box, .accordion .accordion-body { font-size: 0.875rem; }
	.post-video-box { margin: 2rem 0; }
	h3 { font-size: 1.5rem; }
	.post-details-box p + h3, .post-details-box p + h4, .post-details-box p + h5, .post-details-box p + h6 { margin-top: 2.95rem; }
	.highlight-text h2 .highlight-video { min-width: 6rem; margin-bottom: 0.1rem; }
	.accordion .accordion-button { font-size: 1.5rem; }
	.accordion .accordion-item { padding-bottom: 1.25rem; margin-bottom: 1.25rem; }
	.card.accredited-registrars-list .rdt_TableHead > div { padding-top: 0.6rem; padding-bottom: 0.6rem; }
	.tld-name-tabs-box { margin-bottom: .7rem; }
	.tld-tabs-title-box .btn { margin-left: 0; }
	.tld-name-tabs-box h4 { margin-top: 0 !important; }
	.trusted-partner-list .card .btn { max-width: 160px; }
	.domain-model .modal-body { padding: 2.5rem; }
	.domain-model .modal-body p, .domain-model .modal-body h3 { margin-bottom: 1rem; }
	.highlight-text h2 span { margin-right: 0.5rem; }
	.why-tv-section .section-title h2 { font-size: 5.5rem; }
	.video-piller span:nth-child(1)::after, .video-piller span:nth-child(3)::after { height: 2rem; }
	.screen-video { padding: 8rem 0; }
	.whos-text-details h2 { display: inline; margin-right: 0.4rem; }
	.whos-video { width: 3.4rem; min-width: 3.4rem; margin: 0 0.4rem; display: inline-block; }
	.screen-video-box { margin-bottom: 2rem; }
	.page-search.footer-search { padding: 0.4rem 1rem 0.4rem 1.5rem; }
	.footer-search .form-control, .page-search.footer-search .form-control { font-size: 1.2rem; }
	.page-search.footer-search.search-sticky .form-control, .home-page-search .page-search.search-sticky .form-control { font-size: 1.1rem; }
	.footer-search { padding: 0.3rem 1rem; }
	.devider { height: 35px; }

}