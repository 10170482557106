/* =../../public/Fonts
========================================================================================*/
@font-face {
  font-family: 'Futura LT';
  src: url('../../public/fonts/FuturaLT.eot');
  src: url('../../public/fonts/FuturaLT.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/FuturaLT.woff2') format('woff2'),
      url('../../public/fonts/FuturaLT.woff') format('woff'),
      url('../../public/fonts/FuturaLT.ttf') format('truetype'),
      url('../../public/fonts/FuturaLT.svg#FuturaLT') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GD Noe Display Web';
  src: url('../../public/fonts/GDNoeDisplayWeb-Bold.eot');
  src: url('../../public/fonts/GDNoeDisplayWeb-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/GDNoeDisplayWeb-Bold.woff2') format('woff2'),
      url('../../public/fonts/GDNoeDisplayWeb-Bold.woff') format('woff'),
      url('../../public/fonts/GDNoeDisplayWeb-Bold.ttf') format('truetype'),
      url('../../public/fonts/GDNoeDisplayWeb-Bold.svg#GDNoeDisplayWeb-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura LT';
  src: url('../../public/fonts/FuturaLT-Bold.eot');
  src: url('../../public/fonts/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/FuturaLT-Bold.woff2') format('woff2'),
      url('../../public/fonts/FuturaLT-Bold.woff') format('woff'),
      url('../../public/fonts/FuturaLT-Bold.ttf') format('truetype'),
      url('../../public/fonts/FuturaLT-Bold.svg#FuturaLT-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GDSherpa';
  src: url('../../public/fonts/GDSherpa-Regular.eot');
  src: url('../../public/fonts/GDSherpa-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/GDSherpa-Regular.woff2') format('woff2'),
      url('../../public/fonts/GDSherpa-Regular.woff') format('woff'),
      url('../../public/fonts/GDSherpa-Regular.ttf') format('truetype'),
      url('../../public/fonts/GDSherpa-Regular.svg#GDSherpa-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tusker Grotesk 2800 Super';
  src: url('../../public/fonts/TuskerGrotesk-2800Super.eot');
  src: url('../../public/fonts/TuskerGrotesk-2800Super.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/TuskerGrotesk-2800Super.woff2') format('woff2'),
      url('../../public/fonts/TuskerGrotesk-2800Super.woff') format('woff'),
      url('../../public/fonts/TuskerGrotesk-2800Super.ttf') format('truetype'),
      url('../../public/fonts/TuskerGrotesk-2800Super.svg#TuskerGrotesk-2800Super') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tusker Grotesk 3700';
  src: url('../../public/fonts/TuskerGrotesk-3700Bold.eot');
  src: url('../../public/fonts/TuskerGrotesk-3700Bold.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/TuskerGrotesk-3700Bold.woff2') format('woff2'),
      url('../../public/fonts/TuskerGrotesk-3700Bold.woff') format('woff'),
      url('../../public/fonts/TuskerGrotesk-3700Bold.ttf') format('truetype'),
      url('../../public/fonts/TuskerGrotesk-3700Bold.svg#TuskerGrotesk-3700Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



/* =Box Sizing 
========================================================================================*/
* { -webkit-box-sizing:border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }
*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }
input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="search"], textarea, select, input[type="button"], input[type="submit"], button { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -o-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box;  }


/* =Deafult Tag & General Classes
========================================================================================*/
html, body { -webkit-font-smoothing:antialiased; -moz-font-smoothing:antialiased; -ms-font-smoothing:antialiased; font-smoothing:antialiased; /* Fix for webkit rendering */ -webkit-text-size-adjust:100%; height: 100%; }
html { overflow-y: inherit !important; }
body { font-size:18px; line-height:1.45; letter-spacing: 0.05rem; font-weight: normal; font-family: 'Futura LT', Arial, Helvetica, sans-serif; font-weight: 500; color:#fff; background-color: #000; padding-top: 80px; }
img { vertical-align:top; border:0; }
a, input[type="button"], input[type="submit"], button, table th, table td { -webkit-transition:0.5s all; transition:0.5s all; }
.allanim { -webkit-transition:0.5s all; transition:0.5s all; }
a { color:#5CFFFA; text-decoration:none;}
a:hover { color:#5CFFFA; text-decoration:underline; }
button:focus { outline: none; }
a:focus { outline: none; }
@media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
	a:active { background-color:transparent; }
}
b, strong { font-weight: bold; font-family: 'Tusker Grotesk 3700', 'Lato', sans-serif;  }
p b, p strong { font-weight: bold; font-family: 'Futura LT', 'Lato', sans-serif;  }

small, .small { font-size: 85%; }
sub, sup { font-size:80% }
p { margin:0 0 20px 0; }
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { line-height:1.2; margin:0 0 10px; color: #fff; font-family: 'Tusker Grotesk 3700', 'Lato', sans-serif; font-weight: bold; }
h1, .h1 { font-size:16rem; text-transform: uppercase; } 
h2, .h2 { font-size:5.625rem; text-transform: uppercase; }
h3, .h3 { font-size:2.945rem; }
h4, .h4 { font-size:1.82rem; }
h5, .h5 { font-size:1.125rem; }
h6, .h6 { font-size:1rem; }
.font-xl { font-size: 1.5rem; }
.font-l { font-size: 1.125rem; }
ul li { line-height:1.45; }
img { max-width: 100%; height: auto; }
.container { max-width: 1560px; width: 80%; }
#main { position: relative; z-index: 2; }

.page-loader { position: fixed; top: 0; left: 0; right: 0; bottom: 0; margin: auto; z-index: 999999; }
.page-loader.animation-done { opacity: 0; visibility: hidden; }
.star-load { position: absolute; top: 0; left: 0; right: 0; bottom: 0; text-align: center; transform: scale(0); animation: scalestar 1s; opacity: 0; visibility: hidden; background-color: #000; transform-origin: center center; display: flex;
  align-items: center;
  justify-content: center; }
.star-load img, .star-load svg { width: auto; height: auto; max-height: 100%; max-width: 100%; }
.star-load svg path { fill: #fff; }

.sc-bmzYkS button {
  border: 1px #fff solid;
  border-radius: 0px;
  margin: 0 3px;
  padding: 0;
}

.sc-bmzYkS button svg {
  fill: #fff;
}

@keyframes scalestar {
  0%   { transform: scale(0); opacity: 1; visibility: visible; }
  80% { transform: scale(10); opacity: 1; visibility: visible; }
  90% { transform: scale(10); opacity: 0; visibility: hidden; }
  100% { transform: scale(10); opacity: 0; visibility: hidden; }
}

.loader-piller { position: fixed; left: 0; right: 0; top: 0; bottom: 0; opacity: 0; visibility: hidden; /*animation: pilleropacity 2.16s;*/ animation: pilleropacity 2.15s; animation-delay: 0.55s; } 
@keyframes pilleropacity {
  0%   { opacity: 0.4; visibility: visible; } 30% { opacity: 1; visibility: visible; } 100% { opacity: 1; visibility: visible; }
}

.loader-piller:hover { opacity: 1; }
.loader-piller .col { position: relative; }

.loader-piller span { display: block; width: 100%; height: 100%; position: absolute; bottom: 0; left: 0; right: 0; animation: pilleranimation 1.8s;  animation-delay: 0.9s;} 
.piller-02 span { animation-delay: 1.4s; }
.piller-03 span { animation-delay: 1.8s; }
.piller-04 span { animation-delay: 2.2s; }
@keyframes pilleranimation { 0% { height: 100%; } 50% { height: 0; } 100% { height: 0; } }

/* =Header Css
========================================================================================*/
#header { padding: 1.188rem 2.5rem; position: fixed; top: 0; left: 0; right: 0; background-color: #000; z-index: 9; }
.logo { max-width: 85px; width: 100%; } 
.header-menu { margin-left: auto; }
.logo a { display: block; }
.logo figure { margin: 0; }
.logo svg { fill: #fff; width: 100%; height: auto; }
.header-menu .navbar-nav { flex-direction: row; font-weight: bold; font-size: 0.938rem; margin-left: auto; }
.header-menu .nav-item { margin-left: 2.188rem; letter-spacing: 0.05rem; text-transform: uppercase; }
.header-menu .nav-item a { color: #fff; text-decoration: none; }
.header-menu .nav-item a:hover, .header-menu .nav-item a.active { color:#03F500; }
.header-menu .nav-item:nth-child(2) a:hover, .header-menu .nav-item:nth-child(2).active a { color: #03f500; }
.header-menu .nav-item:nth-child(3) a:hover, .header-menu .nav-item:nth-child(3).active a { color: #ff00b8; }
.header-menu .nav-item:nth-child(4) a:hover, .header-menu .nav-item:nth-child(4).active a { color: #5cfffa; }
.header-menu .nav-item:nth-child(5) a:hover, .header-menu .nav-item:nth-child(5).active a { color: #f5ff03; }
.header-menu .nav-item:nth-child(6) a:hover, .header-menu .nav-item:nth-child(6).active a { color: #0005ff; }

.navbar-toggler { margin-left: auto; height: 22px; width: 22px; border-radius: 0; position: relative; display: none; }
.menu-lines:before, .menu-lines:after { height: 2px; width: 100%; content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; background: #fff; transform: rotate(0); -webkit-transition: 0.5s all; transition: 0.5s all; } 
.menu-lines:before { top: -5px; } 
.menu-lines:after { bottom: -5px; }

.navbar-toggler:not(.collapsed) .menu-lines:before { top:0; transform: rotate(45deg); }
.navbar-toggler:not(.collapsed) .menu-lines:after { bottom: 0; transform: rotate(-45deg); }

.menu-left-line { display: none; }



/* =Footer  Css
========================================================================================*/
#footer { padding: 2rem 2.5rem; font-family: 'GDSherpa', 'Lato', sans-serif; font-size: 16px; }
.footer-top { padding-bottom: 1.5rem; margin-bottom: 1.5rem; border-bottom: 1px solid #767676; }
.footer-logo-box { width: 100%; }
.footer-logo-box a { display: block; }
.footer-logo-box figure { margin: 0; }
.footer-logo-box figure svg { width: 100%; height: auto; fill: #fff; }
.flogo { max-width: 70px; width: 100%; margin-right: 1.1rem; }
.gd-powred-by-logo { max-width: 165px; max-width: 230px; width: 100%; }
.fsocial-box { width: 100%; justify-content: end; }
.fsocial-box a { margin-right: 1.25rem; width: 1.875rem; }
.fsocial-box a:last-child { margin-right: 0; }
.fsocial-box a svg { fill: #fff; width: 100%; height: auto; -webkit-transition:0.5s all; transition:0.5s all; }
.fsocial-box a:hover svg { fill: #5CFFFA; }
.fmenu { margin-top: 1.4rem; }
.fmenu ul { margin: 0; padding: 0; list-style: none; display: flex; flex-direction: row; flex-wrap: wrap; }
.fmenu ul li { margin: 0.6rem 1.2rem; line-height: 1; }
.fmenu ul li:first-child { margin-left: 0; }
.fmenu ul li:last-child { margin-right: 0; }
.fmenu ul li a { color: #AFAFAF; text-decoration: none; }
.fmenu ul li a:hover { color: #5CFFFA; }
.footer-bottom { font-size: 13px; }
.footer-bottom-menu ul { margin: 0; padding: 0; list-style: none; display: flex; justify-content: end; }
.footer-bottom-left { margin-left: auto; }
.footer-bottom-right { width: 100%; }
.footer-bottom-sell-note p { margin: 0; white-space: nowrap; }
.footer-bottom-menu ul li { line-height: 1; margin-right: 1.5rem; }
.footer-bottom-menu ul li:last-child { margin-right: 0; }
.footer-bottom-menu ul a { color: #AFAFAF; text-decoration: none; }
.footer-bottom-menu ul a:hover { color: #5CFFFA; } 
.footer-bottom-menu { margin-bottom: 1rem; }
.footer-bottom-copyright { margin-bottom: 1rem; }
.footer-bottom-copyright p, .footer-bottom-note p { margin: 0; }

/* =Buttons Css
========================================================================================*/
.btn { border-width: 2px; border-style: solid; background-color: transparent; font-size: 1.125rem; text-transform: uppercase; font-family: "Futura LT"; font-weight: bold; padding: 1.013rem 1.813rem; border-radius: 0; display: inline-block; text-decoration: none; box-shadow: none; position: relative; z-index: 1; overflow: hidden; }
.btn:after { content: ""; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; border-radius: 50px; height: 50px; width: 50px; transform: scale(0); transition: 0.5s all; z-index: -1; }
.btn:hover:after, .btn:active:after, .btn:focus:after { transform: scale(5); border-radius: 0; }
.btn.btn-primary { border-color: #5CFFFA; color: #5CFFFA; background-color: transparent; }
.btn.btn-primary:after { background-color: #5CFFFA; }
.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus { border-color: #5CFFFA; color: #000; box-shadow: none; text-decoration: none; }



/* =Form Css
========================================================================================*/



/* =Home page Css
========================================================================================*/
.heading-video { /*max-width: 400px;*/ max-width: 43vmin; width: 30%; margin: -0.9rem auto 0.4rem; /*padding-top: 56.25%;*/ position: relative; font-size: 0; /*height: 0;*/ overflow: hidden; }
.heading-video.aos-animate { height: auto; }
.heading-video video { /*position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto;*/ height: 100%; width: 100%; object-fit: cover; }
.heading-video-box { position: relative; padding-top: 56.25%; overflow: hidden; }
.heading-video-box video { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
.blink-anim { position: relative; }
.blink-anim:before, .blink-anim:after { content: attr(data-anim); opacity: 0; position: absolute; top:0; left:0; } 
.blink-anim:before { color: #fe1010; z-index: -1; }
.blink-anim:after { color: #0005ff; z-index: -2; }
.blink-anim:hover {   color: #ff15ff; }
.blink-anim:hover:before { animation: glitch 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite; opacity: 0.8; }
.blink-anim:hover:after { animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite; opacity: 0.8; }

@keyframes glitch {
  0% {-webkit-transform: translate(0); transform: translate(0); }
  20% { -webkit-transform: translate(-5px, 5px); transform: translate(-5px, 5px); transform: skewX(10deg); }
  40% { -webkit-transform: translate(-5px, -5px); transform: translate(-5px, -5px); }
  60% { -webkit-transform: translate(5px, 5px); transform: translate(5px, 5px); }
  80% { -webkit-transform: translate(5px, -5px); transform: translate(5px, -5px); }
  to { -webkit-transform: translate(0); transform: translate(0); }
}
.main-banner-box { height: calc(100vh - 175px); height: calc(100vh - 166px); /*position: relative; z-index: 10;*/ }
.main-banner-box h1 { /*font-size: 22vmin;*/ font-size: 21vmin; font-size: 19vmin; margin: 0; }
.main-banner-box h1 > span { display: inline-block; }
.domain-search-box { background-color: #000eec; padding: 2rem 0; position: sticky; top: 112px; }
.domain-search-box h4 { margin: 0; }

.banner-text-details { max-width: 584px; margin: 0 auto; width: 100%; }
.banner-text-details p:last-child { margin-bottom: 0; }
@keyframes arrowAnim {
  0% { transform: translate3d(0,-150%,0) scale(.5); }
  30% { transform: translate3d(0,-50%,0) scale(1); }
  70% { transform: translate3d(0,-50%,0) scale(1); }
  95% { transform: translate3d(0,70%,0) scale(.5); }
  100% { transform: translate3d(0,70%,0) scale(.5); }
}
.down-arrow-anim { height:2.813rem; width: 2.813rem; border: 2px solid #FFFFFF; border-radius: 50%; position: relative; overflow: hidden; margin: 1rem auto; display: flex; align-items: center; justify-content: center; } 
.down-arrow-anim svg { position: absolute; top: 50%; -webkit-animation: arrowAnim 2s cubic-bezier(.25,.46,.45,.94) infinite forwards; animation: arrowAnim 2s cubic-bezier(.25,.46,.45,.94) infinite forwards; transform: translateY(-50%);  width: 18px; height: 22px; }

.main-banner-box > .container > span > span {
  transform: none;
}

.page-title figure { max-width: 107px; margin: 0 auto 1.4rem; width: 100%; }
.page-title-box { padding: 3rem 0; } 
.page-title { text-align: center; margin: 0 auto; width: 100%; } 
.page-title figure svg { width: 100%; height: auto; fill: #fff; }
.page-title h1 { margin: 0; text-transform: uppercase; }
.page-title p { max-width: 628px;  width: 100%; margin-left: auto; margin-right: auto; }
.page-title p:last-child { margin-bottom: 0; }


.devider { width: 90px; /*height: 30px;*/ height: 52px;  position: relative; margin: 0 auto; padding-top: 5px; }
.devider:before { content: ""; position: absolute; left: 0; right: 0; top: 3px; width: 100%; height: 2px; background: #fff; }
.devider:after { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 8px; width: 2px; background: #fff; animation: leftRightAnim 5s infinite; }

@keyframes leftRightAnim {
  0% {-webkit-transform: translateX(0); transform: translateX(0); }
  50% { -webkit-transform: translateX(88px); transform: translate(88px); }
  100% {-webkit-transform: translateX(0); transform: translateX(0); }
}
.devider span { position: absolute; width: 25%; bottom: 0; height: 25%; animation: heightAnim 2s infinite; }
.devider span:nth-child(1) { background: #F5FF03; left: 0; }
.devider span:nth-child(1):after { content: ""; position: absolute; left: 0; right: 0; top: 100%; height: 3px; background: #FF00B8; }
.devider span:nth-child(2) { background: #5CFFFA; left: 25%; animation-delay: 0.5s; }
.devider span:nth-child(3) { background: #03F500; left: 50%; animation-delay: 1s; }
.devider span:nth-child(3):after { content: ""; position: absolute; left: 0; right: 0; top: 100%; height: 3px; background: #0005FF; }
.devider span:nth-child(4) { background: #FF00B8; left: 75%; animation-delay: 1.5s; }
@keyframes heightAnim {
  0% { height: 40%; }
  25% { height: 70%; }
  50% { height: 55%; }
  75% { height: 40%; }
  100% { height: 20%; }
}

.section-row { padding: 3.125rem 0; }
.section-title p:last-child { margin-bottom: 0; }
.section-title p { max-width: 550px; width: 100%; margin-left: auto; margin-right: auto; }
.hignlight-story-box { max-width: 585px; margin-left: auto; width: 100%; line-height: 1.3; }
.hignlight-story-box h2 { line-height: 1; text-transform: uppercase; }

.domain-process-main-box .small-container { max-width: inherit; width: 100%; }
.domain-process-main-box .container { width: 100%; }
.sliderwith-no-title .splide__arrows.splide__arrows--ltr { top: 30.5rem; }
.why-tv-section .section-title p { max-width: 590px; }
.why-tv-section .section-title h2 { line-height: 1; }

.autotype-text { overflow: hidden; border-right: 3px solid #5CFFFA; white-space: nowrap; margin: 0 auto; animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite; display: inline-block; line-height: 1.1; }
/*@keyframes typing { from { width: 0 } to { width: 100% } }*/
@keyframes blink-caret { from, to { border-color: transparent } 50% { border-color: #5CFFFA; } }

.why-tv-box { position: relative; padding: 1rem 0; z-index: 1; }
.bg-color-piller { position: absolute; top: 0; bottom: 0; left: 0; width: 6px; width: 100%; }
.why-tv-box h2, .why-tv-box p { margin: 1rem 0; }
.screen-video { max-width: 567px; margin: 0 auto; width: 67%; padding: 20rem 0; }
.screen-video video { width: 100%; height: auto; border: 0; }
.screen-video-box { position: relative; z-index: 1; margin-bottom: 6rem; }
.video-piller { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; display: flex; overflow: hidden; }
.video-piller > span { position: absolute; width: 25%; max-width: 25%; bottom: 0; height: 100%; }
.video-piller > span:nth-child(1) { left: 0; }
.video-piller > span:nth-child(1) span { background: #F5FF03; }
.video-piller > span:nth-child(2) { left: 25%; }
.video-piller > span:nth-child(2) span { background: #5CFFFA; }
.video-piller > span:nth-child(3) { left: 50%; }
.video-piller > span:nth-child(3) span { background: #03F500; }
.video-piller > span:nth-child(4) { left: 75%; }
.video-piller > span:nth-child(4) span { background: #FF00B8; }
.video-piller > span:nth-child(1)::after, .video-piller > span:nth-child(3)::after { content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 6rem; }
.video-piller > span:nth-child(1)::after { background: #FF00B8; }
.video-piller > span:nth-child(3)::after { background: #0005FF; }


.whos-text-details { margin-top: 2.5rem; text-align: center; /*max-width: 1380px; margin-left: auto; margin-right: auto; width:100%;*/ }
.whos-text-details h2 { display: flex; white-space: normal; flex-wrap: wrap; justify-content: center; }
.whos-video { width: 8.75rem; min-width: 8.75rem; margin: 0 1.5rem; }


/* =Story page Css
========================================================================================*/
.story-video-box { position: relative; }
video { width: 100%; height: auto; }
.video-btn { background: #03F500; height: 9rem; width: 9rem; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; font-size: 0; border: 0; box-shadow: none; outline: none; } 
.video-btn::before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border-top: 1.2rem solid transparent; border-bottom: 1.2rem solid transparent; border-left: 1.5rem solid #000; width: 0; height: 0; margin: auto; } 

.highlight-text { background: #f5fe03; color: #0005ff; overflow: hidden; position: relative; padding-top: 2.2rem; }
.highlight-text h2 { color: inherit; white-space: nowrap; font-size: 18.75rem; line-height: 1.05; margin: 0; text-transform: uppercase; display: flex; align-items: center; width: 100%; }
.highlight-text h2 span { display: flex; align-items: center; justify-content: center; margin-right: 1.5rem; }

.highlight-text h2 .highlight-video { margin: 0 1.6rem; flex-direction: column; min-width: 15.125rem; align-items: start; }
.highlight-video h6 { color: inherit; font-family: "Futura LT"; font-weight: bold; text-transform: none; }
.highlight-video h6 a, .highlight-text span a { color: inherit; text-decoration: none ; }
/*.highlight-text h2 .scroller > span > span { margin: 0; }*/

.top-line-box { position: absolute; top: 0; right: 0; left: 0; height: 0.625rem; }
.top-line-box span { position: absolute; top: 0; width: 50%; height: 100%; }
.top-line-box .line-left { left: 0; }
.top-line-box .line-right { right: 0; }
.top-line-box .line-left:before, .top-line-box .line-left:after, .top-line-box .line-right:before, .top-line-box .line-right:after { content: "";  position: absolute; top: 0; width: 50%; height: 100%; }
.top-line-box .line-left:before { left: 0; background: #F5FF03; }
.top-line-box .line-left:after { right: 0; background: #5CFFFA; }
.top-line-box .line-right:before { left: 0; background: #03F500; }
.top-line-box .line-right:after { right: 0; background: #FF00B8; }
.founder-quote h2 { line-height: 1.15; }
.showcase-box figure { margin: 0 0 1rem 0; position: relative; overflow: hidden; }
.showcase-box figure a { display: block; }
.showcase-box figure img { transform: scale(1); transition: 0.5s all; }
.showcase-box a { color: inherit; text-decoration: none; }
.showcase-box:hover img { transform: scale(1.1); }
.footer-search { background: #0005FF; padding: 2rem 2.5rem; }
.footer-search a { text-decoration: none; }
.footer-search .btn { display: flex; align-items: center; min-width: 182px; }
.footer-search .btn svg { min-height: 1.938rem; height: 1.938rem; min-width: 1.938rem; width: 1.938rem; margin-right: 0.6rem; }
.footer-search .btn svg path { fill: #5CFFFA; transition: 0.5s all; }
.footer-search .btn:hover svg path, .footer-search .btn.btn-primary:active svg path, .footer-search .btn.btn-primary:focus svg path { fill: #000; }
.footer-search .form-control { padding: 0; padding-right: 1.3rem; height: auto; background: transparent; border: 0; color: #5CFFFA; font-size: 3.438rem; font-family: "Futura LT"; font-weight: bold; box-shadow: none; outline: none;  }
.footer-search .form-control::-webkit-input-placeholder { opacity: 1; color: #5CFFFA; }
.footer-search .form-control::-moz-placeholder { opacity: 1; color: #5CFFFA; }
.footer-search .form-control:-ms-input-placeholder { opacity: 1; color: #5CFFFA; }
.footer-search .form-control:-moz-placeholder { opacity: 1; color: #5CFFFA; }

.splide.stories-slider { position: static; }
.stories-slider { max-width: 743px; margin: 0 auto; width: 100%; } 
.stories-slider .splide__track { overflow: inherit; }
.stories-box { opacity: 0; visibility: hidden; transform: translateY(-20%); }
.is-visible .stories-box { opacity: 1; visibility: visible; transform: translateY(0); transition: 0.35s all;  }
.stories-mian-box { padding: 0 1.25rem; }
.stories-img { position: relative; z-index: 1; overflow: hidden; }
.stories-img a { display: block; }
.stories-img .top-line-box { transform: translateY(-100%); transition: 0.35s all; }
.stories-mian-box:hover .stories-img .top-line-box { transform: translateY(0);  }
.stories-box h2 { margin: 0; }
.stories-box { line-height: 1.3; }
.stories-btn-box { flex-wrap: wrap; }
.stories-btn-box a.btn { text-decoration: none; }
.stories-btn-box a { text-decoration: underline; margin-right: 2rem; font-family: "Futura LT"; font-weight: bold; }
.stories-btn-box a:last-child { margin-right: 0; }
.stories-slider .splide__pagination { display: none; }
.stories-slider .splide__arrows { position: absolute; top: 47rem; top: 50rem; right: 2rem; display: flex; }
.splide__arrows .splide__arrow { height: 3.75rem; width: 3.75rem; background: transparent; border: 2px solid #fff; color: #fff; border-radius: 0; transition: 0.35s all; position: relative; transform: none; left: 0; right: 0; top: 0; bottom: 0; margin: 0 -2px 0 0; opacity: 1; z-index: 1; }

.splide__arrows .splide__arrow:hover { z-index: 2; }
.splide__arrows .splide__arrow svg { fill: #fff; transition: 0.25s all; }
.splide__arrows .splide__arrow:hover svg { fill: #000; }
.splide__arrows .splide__arrow--prev:hover { background-color: #03F500; border-color: #03F500; }
.splide__arrows .splide__arrow--next:hover { background-color: #FF00B8; border-color: #FF00B8; }

.marquee { display: block; width: 200%; height: 100%; position: absolute; overflow: hidden; animation: marquee 15s linear infinite; }
.marquee h2 { float: left; width: 50%; }
@keyframes marquee { 0% { left: 0; } 100% { left: -100%; } }
.highlight-text .no-show { opacity: 0; visibility: hidden; }
.page-search.footer-search .form-control { font-size: 2.5rem; transition: 0.5s all; }
.page-search.footer-search .btn { border-color: transparent; }
.page-search.footer-search { padding: 0.4rem 2rem; position: sticky; top: 78px; z-index: 3; }
.home-page-search { position: sticky; top: 78px; z-index: 12; transition: 0.35s all; }
.headersearchhide { transform: translateY(-50px); opacity: 0; visibility: hidden; }
.headersearchshow { transform: translateY(0); opacity: 1; visibility: visible; } 
.home-page-search .page-search .form-control { /*font-size: 3.438rem;*/ font-size: 3.038rem; }
.page-search.footer-search.search-sticky .form-control, .home-page-search .page-search.search-sticky .form-control { font-size: 1.9rem; }

.post-main-img img { /*height: calc(100vh - 160px);*/ width: 100%; height: auto; object-fit: cover; }

.post-details-box p, .post-details-box h2, .post-details-box h3, .post-details-box h4, .post-details-box h5, .post-details-box h6, .post-details-box ul { max-width: 900px; width: 100%; margin-bottom: 1.975rem; }
.post-details-box h1 { margin-bottom: 2.5rem; max-width: 1056px; width: 100%; }
.post-details-box { font-size: 1.5rem; line-height: 1.4; }
.post-details-box h3, .post-details-box h4, .post-details-box h5, .post-details-box h6 { font-family: 'Futura LT'; font-weight: bold; }
.post-details-box p + h3, .post-details-box p + h4, .post-details-box p + h5, .post-details-box p + h6 { margin-top: 7.5rem; }
.post-video-box { margin: 7rem 0; }

.post-video-box { position: relative; }
.post-video-box .top-line-box { top: auto; bottom: 1rem; z-index: 1; left: 1.5rem; right: 1.5rem; }
.post-video-box .story-video-box { font-size: 0; }
.interview-share-link { margin-top: 6rem; margin-bottom: 3rem; }
.interview-share-link a { color: #fff; text-transform: uppercase; font-weight: bold; font-family: 'Futura LT', Arial, Helvetica, sans-serif; text-decoration: underline; }
.interview-share-link a:hover { color: #5CFFFA; }

.post-info { font-size: 0.875rem; }
.post-info h5 { margin: 0 0 0.2rem 0; font-size: inherit; }
.post-info p { margin: 0; }

/* =Faq page Css
========================================================================================*/
.accordion { position: relative; z-index: 1; }
.accordion .accordion-item { background: transparent; border-width: 0 0 1px 0; border-style: solid; border-color: #fff; padding-bottom: 3.75rem; margin-bottom: 3.75rem; border-radius: 0; }
.accordion .accordion-button { background: transparent; color: #fff; font-size: 3.438rem; font-family: "Futura LT"; font-weight: bold; padding: 0; outline: none; box-shadow: none; line-height: 1.25; padding-right: 3.5rem; position: relative; }
.accordion .accordion-button span { display: inline-block; max-width: 900px; width: 100%; }
.accordion .accordion-body { padding: 1.5rem 0 0 0; font-size: 1.5rem; line-height: 1.4; max-width: 900px; width: 100%; }
.accordion .accordion-body p:last-child, .accordion .accordion-body ul:last-child { margin: 0; }
.accordion-button:before, .accordion-button:after { content: ""; position: absolute; top: 50%; bottom: 0; right: 0; width: 2.75rem; height: 2px; background: #fff; transform-origin: center center; }
.accordion-button:after { transform: rotate(-90deg); }


/* =Copntact page Css
========================================================================================*/
.contact-address-middle, .contact-address-bottom { margin-top: 5rem; max-width: 350px; }
.contact-address-box  p:last-child { margin-bottom: 0; }
.contact-address-middle h5 { font-size: 0.938rem; font-family: 'Futura LT', Arial, Helvetica, sans-serif; font-weight: 500; margin-bottom: 1.25rem; }
.contact-address-bottom address { margin: 0; }
.contact-from .form-group { position: relative; margin-bottom: 1.875rem; z-index: 1; }
.contact-from .form-control { height: 4.25rem; border-radius: 0; background-color: transparent; outline: none; box-shadow: none; border: 1px solid #FFFFFF; color: #fff; font-size: 1.125rem; padding: 0 1.25rem; }
.contact-from .form-group label { position: absolute; top: 0.75rem; left: 0.25rem; color: #fff; font-size: 1.125rem; background: #000; padding: 0.5rem 1rem; transition: 0.35s all; z-index: -1; }
.required { color: #DB1802; }
.contact-from .form-control:focus ~ label, .contact-from .form-control.filled ~ label { top: -1.4rem; padding-left: 1.3rem; left: 0.9rem; z-index: 2; }
.contact-from .form-control option { color: #000; }
.custom-select { position: relative; }
.custom-select .form-select { background: transparent; padding-right: 3.4rem; }
.custom-select:after { content: ""; position: absolute; top: 1.3rem; right: 1.3rem; border-bottom: 2px solid #fff; border-right: 2px solid #fff; height: 1.05rem; width: 1.05rem; transform: rotate(45deg); z-index: -1; }
.contact-from textarea.form-control { height: 16.625rem; padding-top: 1.5rem; padding-bottom: 1.5rem; }
.contact-address-bottom p a { font-weight: bold; text-transform: uppercase; text-decoration: underline; }

.form-group small.text-danger { padding-top: 0.5rem; display: block; }
.card.accredited-registrars-list { background: transparent; }
.card.accredited-registrars-list .eSTlnH, .card.accredited-registrars-list .rdt_TableRow, .card.accredited-registrars-list .rdt_TableHeadRow, .card.accredited-registrars-list header, .accredited-registrars-list .jxflYm { background: transparent; color: inherit; }
.accredited-registrars-list .rdt_TableHead { color: inherit; background: transparent; font-size: inherit; font-weight: bold; border-bottom: 1px solid #fff; text-transform: uppercase; margin-bottom: 1rem; }
.accredited-registrars-list .rdt_TableHead > div { min-height: inherit; padding: 1.6rem 0; }
.accredited-registrars-list .sc-kDDrLX { opacity: 1; font-size: 0; border-right: 2px solid #fff; border-bottom: 2px solid #fff; padding: 0; height: 0.9rem; width: 0.9rem; transform: rotate(-135deg); position: relative; top: 0.3rem; left: 0.8rem; }
.accredited-registrars-list .sc-kDDrLX.ccHTVR { transform: rotate(45deg); top: -0.2rem; }
.card.accredited-registrars-list .rdt_TableRow { outline: none; border: 0; font-size: inherit; min-height: inherit; transition: 0.35s all; position: relative; z-index: 1; }
.card.accredited-registrars-list .rdt_TableRow:hover { color: #5CFFFA; }
/*.card.accredited-registrars-list .rdt_TableRow:after { content: ""; position: absolute; left: 0; top: 0; bottom: 0; background: #5CFFFA; width: 0; transition: 0.35s all; z-index: -1; }
.card.accredited-registrars-list .rdt_TableRow:hover:after { width: 100%; }*/
.card.accredited-registrars-list .rdt_TableRow > div { padding-top: 1.1rem; padding-bottom: 1.1rem; }
.gJhVev { transition: 0.5s all; }
.accredited-registrars-list .gJhVev:hover, .accredited-registrars-list .gJhVev:focus { opacity: 1; color: #5CFFFA; }
.accredited-registrars-list .gJhVev:hover span, .accredited-registrars-list .gJhVev:focus span, .accredited-registrars-list .gJhVev:hover span.__rdt_custom_sort_icon__ *, .accredited-registrars-list .gJhVev:focus span.__rdt_custom_sort_icon__ * { opacity: 1; }
.card.accredited-registrars-list .rdt_TableRow > div:first-child, .rdt_TableHeadRow > div:first-child { padding-left: 0; }
.card.accredited-registrars-list .rdt_TableRow > div:last-child, .rdt_TableHeadRow > div:last-child { padding-right: 0; }
.accredited-registrars-list header .form-control { height: 3.35rem; border-radius: 0; background-color: transparent; outline: none;box-shadow: none; border: 1px solid #FFFFFF; color: #fff; font-size: 1.125rem; padding: 0 1.25rem; width: 100%; max-width: 290px; margin-left: auto; margin-right: 0; }
.accredited-registrars-list header { padding-left: 0; padding-right: 0; }
.sc-ezWOiH button { border-radius: 0; transition: 0.5s all; background: transparent; }
.sc-ezWOiH button:hover, .sc-ezWOiH button:hover:hover:not(:disabled) { background: #5CFFFA; }
.sc-ezWOiH button svg { fill: #fff; transition: 0.5s all; vertical-align: top; }
.sc-ezWOiH button:hover svg { fill: #000; }
.rdt_Pagination .sc-ezWOiH { margin-left: auto; }
.accredited-registrars-list .rdt_Pagination { border-top: 1px solid #fff; padding: 0; margin-top: 1rem; padding-top: 1rem; background: transparent; color: inherit; font-size: inherit; }
.accredited-registrars-list .rdt_Table { background: transparent; color: inherit; }
/* .recaptcha-box { display: inline-block; font-size: 0; background: #f9f9f9; border-radius: 5px; overflow: hidden; } */
.clip-img { display: flex; justify-content: center; position: relative; overflow: hidden; }
.clip-img h2 { background-clip: text; -webkit-background-clip: text; color: transparent; margin: 0; font-size: 50vw; text-align: center; background-repeat: no-repeat; background-size: cover; background-position: center center; line-height: 1.1; }
.gd-ico { margin: 0 auto 4rem; max-width: 6.688rem; }
.gd-ico img { width: 100%; height: auto; }
.text-lime { color: #5CFFFA; }
.gd-text h2 { line-height: 1; }

/* =Search Result page Css
========================================================================================*/
.tld-info-box { /*border-radius: 1rem;*/ border: 1px solid #fff; }
.premium-label { font-size: 0.75rem; padding: 0.5rem 1rem 0.6rem 1rem; line-height: 1; border-radius: 0 1rem 1rem 0; white-space: nowrap; text-transform: uppercase; }
.premium-label figure { width: 1rem; height: 1rem; margin: 0 0 0 0.4rem; }
.premium-label figure svg { vertical-align: top; }

.tld-tabs .nav-tabs .nav-link { border: 0; font-size: 1.125rem; padding: 1.5rem 0 2.3rem; margin: 0; font-family: "Futura LT"; color: #767676; position: relative; border-radius: 0; overflow: hidden; transition: 0.5s all; background: transparent; }
.tld-tabs .nav-tabs .nav-item { margin-left: 1.5rem; }
.tld-tabs .nav-tabs .nav-item:first-child { margin-left: 0; }
.tld-tabs .nav-tabs .nav-item.show .nav-link, .tld-tabs .nav-tabs .nav-link.active, .tld-tabs .nav-tabs .nav-link:hover { color: #5CFFFA; }
.tld-tabs .nav-tabs { border-bottom: 1px solid #d6d6d6; }
.tld-tabs .nav-tabs .nav-link:after { content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 1rem; background: #5CFFFA; transform: translateY(100%); opacity: 0; visibility: hidden; transition: 0.5s all; }
.tld-tabs .nav-tabs .nav-item.show .nav-link:after, .tld-tabs .nav-tabs .nav-link.active:after { opacity: 1; visibility: visible; transform: translateY(0); }
.tld-name-tabs-box h4 { font-size: 1.5rem; white-space: normal; word-break: break-all; font-family: "Futura LT"; font-weight: normal; }
.tld-name-tabs-box h4 strong { font-family: inherit; }
.tld-tabs-box { padding: 1rem 0; border-bottom: 1px solid #D6D6D6; }
.tld-tabs-list .tld-tabs-box:last-child { padding-bottom: 0; border-bottom: 0; }
.tld-info-box h3 { white-space: normal; word-break: break-all; }
/*.tld-info-box h3 strong, .tld-name-tabs-box h4 strong { font-family: "Futura LT"; font-weight: bold; }*/

.tld-tabs-title-box .btn { min-width: 130px; margin-left: 0.5rem; }

.trusted-partner-card-box { border: 0; margin-top: 1rem; padding: 2rem 1.5rem; }
.trusted-partner-list .card { padding: 1.2rem; height: 100%; border: 1px solid rgba(255,255,255,0.2); /*border-radius: 0.5rem;*/ border-radius: 0; text-align: center; background-color:#fff; }
.trusted-partner-list .card figure { margin: 1rem auto; max-width: 190px; width: 100%; height: 4rem; display: flex; align-items: center; justify-content: center; }
.trusted-partner-list .card figure img { max-width: 100%; max-height: 100%; height: auto; width: auto; }
.trusted-partner-list .card p { margin: auto 0; padding-bottom: 1.4rem; color: #000; }
.trusted-partner-list .card .btn { max-width: 220px; margin: 0 auto; width: 100%; border-color: #000; color: #000; }
.trusted-partner-list .card .btn:hover, .trusted-partner-list .card .btn:active, .trusted-partner-list .card .btn:focus { color: #fff; }
.trusted-partner-list .card .btn:after { background-color: #000; }

.trusted-all-options-btn { margin: 2.4rem 0 1.6rem; text-align: center; }
.trusted-options-triger { font-family: "Futura LT", "Lato", sans-serif; line-height: 1; text-decoration: none !important; box-shadow: none !important; letter-spacing: 0.05rem; transition: 0.5s all; border-radius: 8px; display: inline-block; padding: 1.4rem 2.675rem 1.1rem; font-size: 1.1rem; border: 0; font-weight: bold; background: #5CFFFA; color: #111; text-transform: uppercase; }
.trusted-options-triger:hover, .trusted-options-triger:active, .trusted-options-triger:focus { background: #5CFFFA; color: #000; }
.trusted-options-triger svg { width: 1.1em; margin-left: 0.7rem; transform: rotate(180deg); position: relative; top: -0.1rem; transition: 0.5s all; }
.trusted-options-triger:not(.collapsed) svg { transform: rotate(360deg); top:-0.1rem; }
.trusted-all-options-btn .trusted-options-triger { border-radius: 0; }
.trusted-options-triger svg path { fill:#000; }
.trusted-all-partner-list { padding-top: 1.5rem; }
.trusted-all-partner-list .card img { filter: grayscale(1); transition: 0.5s all; }
.trusted-all-partner-list .card:hover img { filter: grayscale(0); }
.tld-not-available p a { text-decoration: underline; }
.retailers-note { font-size: 1rem; line-height: 1.5; /*background: rgba(255,255,255,0.15);*/ }

.small-container { max-width: 1340px; margin: 0 auto; width: 90%; }
.tld-available-info-box { border-radius: 1rem; position: relative; overflow: hidden; background: #0005FF; color: #5CFFFA;  }
/*.tld-available-info { padding: 3.9rem 0; }*/
.tld-available-info h2 { white-space: normal; word-break: break-all; margin: 0; }
.tld-available-info * { color: inherit; }
.tld-search-form-box .tld-search-form .form-control { font-family: "Futura LT"; padding-top: 1.4rem; }
.tld-search-form-box .tld-search-form .search-btn { top: -2px; bottom: -2px; right: -2px; }
.tld-not-available h3 figure { margin: 0.513rem 0.813rem 0 0; width: 2.5rem; height: 2.5rem; }
.tld-not-available h3 figure svg { vertical-align: top; width: 100%; height: auto; }
.tld-not-available h3 figure svg path { fill: #FF00B8; }
.tld-not-available h3 { color: #FF00B8; }


.domain-model .modal-body { padding: 5rem; }
.domain-model .modal-body p, .domain-model .modal-body h3 { padding: 0; margin-bottom: 1.5rem; min-width: 100%; }
.domain-model .modal-body p:last-child { margin-bottom: 0; }
.domain-model .modal-body a { color: inherit; text-decoration: underline; }
.modal .modal-content { border-radius: 0; border: 0; box-shadow: 0px 0px 54px rgba(17, 17, 17, 0.07), 0px 48px 36px rgba(17, 17, 17, 0.1), 0px 27px 36px rgba(17, 17, 17, 0.07); background-color: #000; }
.modal-backdrop { background-color: #0005FF; }
.modal-backdrop.show { opacity: 0.5; }
.modal-header .btn-close { opacity: 1; position: absolute; top: 2rem; right: 2rem; outline: none; box-shadow: none; }
.modal-header .btn-close:before, .modal-header .btn-close:after { content: ""; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; height: 0.2rem; width: 100%; border-radius: 50px; background-color: #FF00B8; }
.modal-header .btn-close:before { transform: rotate(45deg); }
.modal-header .btn-close:after { transform: rotate(-45deg); }
.modal-title { line-height: 1.2; word-break: break-all; }

.who-info-box address { margin: 0; }
.modal-header { position: relative; }
.modal-header .btn-close { position: absolute; margin: 0; opacity: 1; right: -0.5rem; top: -0.5rem; }
.modal-header .modal-title { line-height: 1; }
.who-info-box { padding: 1.5rem; border-radius: 1rem; background: rgba(255,255,255,0.1); font-size: 1rem; }
.who-info-box span { display: block; white-space: normal; word-break: break-all; }
.accordion-technical-details .accordion .accordion-button span { margin-right: 0.3rem; width: auto; }
.accordion-technical-details .accordion .accordion-button .accordion-show { display: none; }
.accordion-technical-details .accordion .accordion-button .accordion-hide { display: inline-block; }
.accordion-technical-details .accordion .accordion-button:not(.collapsed) .accordion-hide { display: none; }
.accordion-technical-details .accordion .accordion-button:not(.collapsed) .accordion-show { display: inline-block; }
.accordion-technical-details .accordion .accordion-button { color: #5CFFFA; padding: 0; border: 0; border-radius: 0; box-shadow: none; outline: none; font-size: 1.1rem; font-weight: 500; letter-spacing: 0.05rem; }
.accordion-technical-details .accordion .accordion-item { background: transparent; border: 0;  margin: 0; padding: 0; } 
.accordion-technical-details .accordion .accordion-button:before { display: none; }
.accordion-technical-details .accordion .accordion-button:after { position: static; margin-right: auto; margin-left: 0.8rem; margin-top: 0.5rem; border-right: 2px solid; border-bottom: 2px solid; height: 0.7rem; width: 0.7rem; transform: rotate(225deg); opacity: 1; transition: 0.35s all; transform-origin: center center; background: transparent; }
.accordion-technical-details .accordion .accordion-button.collapsed:after { margin-top: -0.4rem; transform: rotate(45deg); }
.accordion-technical-details .accordion .accordion-body { max-width: 100%; font-size: inherit; padding: 1rem 0 0 0; line-height: 1.3; }
.accordion-technical-details .accordion .accordion-body p:last-child { margin-bottom: 0; }
.accordion-technical-details .accordion .accordion-header { font-size: inherit; }
.accordion-technical-details .accordion .accordion-header .accordion-button { font-size: inherit; }

.technical-note { color: #03F500; }
.modal-content.modal-rounded { border-radius: 1.5rem; }
.who-info-box h6 { font-family: "Futura LT"; font-weight: bold; }
.who-info-box > * { margin-bottom: 0.6rem; }

.domain-process-step { margin: 2rem 0; max-width: 320px; width: 100%; }
.domain-process-step span, .domain-process-step h2 { margin-bottom: 1.5rem; display: block; line-height: 1; }
.domain-process-step p { margin-bottom: 0; }
.step-search span, .step-search h2 { color: #FF00B8; }
.step-select span, .step-select h2 { color: #03F500; }
.step-checkout span, .step-checkout h2 { color: #5CFFFA; }
.domain-process-step.step-disable, .domain-process-step.step-disable h2, .domain-process-step.step-disable span { color: #666; }

@media only screen and (min-width: 992px) {
  .domain-model .modal-lg { max-width: 740px; }
}

.parallax .scroller { display: flex; flex-wrap: nowrap; }
.policy-list table tr td { padding: 1rem .5rem; box-shadow: none; border-color: rgba(255,255,255, 0.2); }
.accredited-registrars-title h1 { font-size: 22vmin; }

.interview-share-link { display: flex; flex-wrap: wrap; align-items: center; }
.interview-share-link button { margin-right: 0.4rem; border: 0; border-radius: 50px; height: 32px; width: 32px; }
.copy-text { display: inline-block; font-size: 14px; opacity: 0; visibility: hidden; transition: 0.5s all; }
.copy-text.copied { opacity: 1; visibility: visible; }

.no-show-label { text-indent: -999rem; display: inline-block; }

/*.main-banner-box>.container .text-center { transform: translateY(-20px); }*/
